body {
    font-family: 'Poppins', sans-serif !important;
}

a {
    text-decoration: none;
}

.css-1byr0tz {
    background-color: #F9FAFB !important;
}


.showSubMenu {
    display: block;
}

.hideSubMenu {
    display: none;
}

[disabled] {
    opacity: 1\;
}


.css-156rdpn-MuiBarElement-root {
    fill: #1660C0 !important;
    width: 10px !important;
    margin-bottom: 10px !important;
    border-top-left-radius: 3px !important;
    border-top-right-radius: 3px !important;
}

.css-10vg0a6-MuiResponsiveChart-container>svg {
    width: 100%;
    height: 100%;
    /* background-color: #1660C0  !important; */
}



.clinic-table tr td {
    background-color: transparent !important;
    border: none;
    font-size: 13px;
    padding-left: 30px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.clinic-table thead {
    background-color: #F2F2F2 !important;
}

.clinic-table tr:nth-child(even) {
    background-color: #ecf3fa;
}

.clinic-table tr:nth-child(odd) {
    background-color: #FCFDFF;
}

.clinic-table tr td:nth-child(1) {
    color: rgb(126, 126, 126);
    font-weight: 600 !important;
}

.clinic-table tr td:nth-child(2) {
    color: rgb(190, 190, 190);
    font-weight: 600 !important;
}

.clinic-table tr td:nth-child(4) {
    color: rgb(190, 190, 190);
    font-weight: 600 !important;
}

.clinic-table tr td:nth-child(3) {
    font-weight: 600 !important;
    color: rgb(190, 190, 190);
}

.clinic-table tr td:nth-child(5) {
    text-align: left;
}

.clinic-table thead tr th {
    background-color: transparent;
    color: #6F6F70;
    font-weight: 700;
    padding-left: 30px;
}

.clinic-table thead tr {
    background-color: white;
    padding-bottom: 10px;
}



.css-i4bv87-MuiSvgIcon-root {
    color: #C8C8C9 !important;
}

.css-tdqp9c-MuiInputAdornment-root {
    color: #C8C8C9 !important;
}

.director-table tr td {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}

.survey-table tr td {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    color: #6F6F70 !important;
}

.survey-table th {
    width: 18%;
}

.survey-table td {
    width: 18% !important;
}

.measure-table th,
td {
    color: #545454 !important;
}

.measure-table th:nth-child(1) {
    width: 10%;
}

.measure-table th:nth-child(2) {
    width: 50%;
}

.measure-table th:nth-child(3) {
    width: 20%;
}

.measure-table th:nth-child(4) {
    width: 20%;
}

.measure-table td:nth-child(1) {
    width: 10%;
}

.measure-table td:nth-child(2) {
    width: 50%;
}

.measure-table td:nth-child(3) {
    width: 20%;
}

.measure-table td:nth-child(4) {
    width: 20%;
}

.measure-create-table th,
td {
    color: #545454 !important;
}

.measure-create-table th:nth-child(1) {
    width: 25%;
}

.measure-create-table th:nth-child(2) {
    width: 15%;
    text-align: center;
}

.measure-create-table th:nth-child(3) {
    width: 20%;
    text-align: center;
}

.measure-create-table th:nth-child(4) {
    width: 15%;
    text-align: center;
}

.measure-create-table th:nth-child(5) {
    width: 25%;
}

.measure-create-table td:nth-child(1) {
    width: 25%;
}

.measure-create-table td:nth-child(2) {
    width: 15%;
    text-align: center;
}

.measure-create-table td:nth-child(3) {
    width: 20%;
    text-align: center;
}

.measure-create-table td:nth-child(4) {
    width: 15%;
    text-align: center;
}

.measure-create-table td:nth-child(5) {
    width: 25%;
    text-align: center;
}

.bg-info {
    background-color: #E5F1FE !important;
}

.tab {
    background-color: white !important;
}

.tab .nav-link.active {
    font-weight: 500 !important;
    border-bottom: 4px solid #6A6A6A !important;
    border-left: none !important;
    border-right: none !important;
    border-top: none !important;
    color: #6A6A6A !important;
    padding: 8px 5px !important;
}

.tab .nav-link {
    color: #6A6A6A !important;
    font-weight: 500 !important;
    padding: 8px 30px !important;
}

.radio-tabs .form-check-input[type="radio"] {
    width: 20px;
    height: 20px;
    border: 1.5px solid #ccc !important;
    margin-right: 30px !important;
}

.surveyCheckbox .form-check-input[type="checkbox"] {
    width: 20px;
    height: 20px;
    border: 1.5px solid #ccc !important;
    margin-right: 30px !important;
}

.radio-tabs .form-check-label {
    font-size: 14px !important;
    color: #383838 !important;
}

/* <label title="" class="form-check-label">On a daily basis</label> */


.text-primary {
    color: #114A95 !important;
}

.bg-primary {
    background: linear-gradient(to top, #11448A 0%, #176CD9 100%) !important;
    border-color: #164990;
    border-left: 1px solid white;
}


.highlightOption {
    background: white !important;
    color: #000 !important;
}

.multiSelectContainer li:hover {
    background: white !important;
    color: #000 !important;
}


.date-pick .react-datepicker {
    border: none !important;
}

.date-pick .react-datepicker__header {
    background: white !important
}

.date-pick {
    width: 260px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}

.date-pick .react-datepicker__current-month {
    display: none;
}

.date-pick .react-datepicker__navigation--next {
    display: none;
}

.date-pick .react-datepicker__navigation--previous {
    display: none;
}

/* 
    .MuiBox-root{min-height: 2000px !important;} */

.css-10ui8dn .simplebar-content {
    overflow-y: scroll !important;
    width: 260px;
}

.user-list-table tr td {
    border: 1px solid rgb(233, 233, 233);
    padding: 10px !important;
    text-align: center;
    font-weight: 600 !important;
    font-size: 13px;
}

.user-list-table tr th {
    background-color: #F2F2F2 !important;
    padding-top: 13px;
    padding-bottom: 13px;
    text-align: center;
    font-weight: 900 !important;
}

.user-list-table tr td:nth-child(5) {
    text-align: center;
}


.accordion-button:not(.collapsed) {
    background-color: #fff !important;
    border-bottom: none !important;
}

.accordion-body {
    border-top: none !important;
}

.VictoryContainer {
    height: 300px !important;
}

.dashboard-chart .apexcharts-text tspan {
    color: #fff !important;
}

.dashboard-chart .apexcharts-bar-area {
    fill: white !important;
}

.surveyDropdowm a::after {
    display: none;
}

.surveyDropdowm:first-child {
    padding: 0;
}

.surveyDropdowm {
    padding: 0;
}

.dropdown-toggle.nav-link {
    padding: 0;
}

.addScoreTxt {
    padding-top: 57px;
    padding-left: 210px;
}

.addScoreTxt .addScoreHeading {
    margin-bottom: 24px;
}

.addScoreTxt .addScoreHeading span {
    font-weight: 600;
}

.addScoreTxt ul li {
    margin-bottom: 10px;
    margin-left: 35px;
}

/* .surveyDropdowm a{
    padding: 0;
} */

.questionTableDropdown.nav {
    justify-content: center;
}

.dropDownItem {
    display: flex;
    align-items: center;
    font-size: 13px;
}

.surveyTable th,
.surveyTable tbody,
.surveyTable tr,
.surveyTable td {
    border: none;
    border-color: none;
}

.surveyTable td:not(:first-child) {
    text-align: center;
}

.hiddenClass {
    display: none;
}


.suveryDateInput input {
    padding: 5px;
    border-radius: 4px;
}

.formInput span {
    display: block;
    font-size: 14px;
    margin-bottom: 5px;
}

.formInputs p {
    display: block;
    font-size: 14px;
    margin: 0px;

}

.formInputs {
    margin-bottom: 20px;
}

.formInputs input {
    padding: 5px;
    border-radius: 3px;
    border: 1px solid #ACACAC;
    width: 100%;
}

.formInputs select {
    padding: 5px;
    border-radius: 3px;
    border: 1px solid #ACACAC;
    width: 100%;
}

.suveryDateInput {
    display: flex;
    align-items: center;
}

.suveryDateInput .formInput {
    margin-right: 16px;
}

.passwordSpan {
    width: 270px;
    margin: 0 auto;
    text-align: left;
}

.passwordSpan ul li {
    list-style: none;
}

.passwordSpan ul li:not(:last-child) {
    margin-bottom: 10px;
}

.passwordSpan ul li span {
    font-size: 12px;
    display: flex;
    align-items: center;
}

.passwordSpan ul li span svg {
    fill: #fff;
    margin-right: 5px;
}

.passwordSpan ul li span.crossMark svg {
    background-color: red;
    padding: 3px;
    border-radius: 50%;

}

.passwordSpan ul li span.checkMark svg {
    background-color: green;
    padding: 3px;
    border-radius: 50%;
}

.answerTableWrp table thead th {
    background-color: #F2F2F2;
    padding: 24px 0;
    color: #6D6D6D;
}

.answerTableWrp table tbody td {
    background-color: #FBFDFF;
    border: 1px solid #E3E3E3;
    vertical-align: middle;
    padding: 24px 0;
}

.answerTableWrp table tbody td button {
    background-color: transparent;
    border: none;
    padding: 0;
}

.answerModal .modal-content {
    min-width: 800px;
}

.staticticsBtn {
    margin-right: 60px;
}

.staticticsBtn button {
    padding: 12px 30px;
    border: none;
    color: #505050;
}

.staticticsBtn button.btn-1 {
    margin-right: 5px;
    border-radius: 12px 0px 0px 12px;
    background-color: #EDF4FF;
}

.staticticsBtn button.btn-2 {
    border-radius: 0px 12px 12px 0;
    background-color: #EBEBEB;
}

.selectBox {
    margin-top: 54px;
}

.selectBox select {
    width: 100%;
    padding: 10px 0;
    border: 1px solid #ACACAC;
    border-radius: 8px;
}

.emojiImg {
    margin-top: 54px;
}

.emojiImg img {
    width: 40px;
}

.shortTxtInput input {
    border: none;
    border-bottom: 1px solid #cbcbcb;
    width: 100%;
}

.shortTxtInput input:focus {
    outline: none;
}

.inputCheck input {
    width: 24px;
    height: 24px;
    margin-right: 5px;
}

.uploadFileWrp {
    margin-top: 60px;
}

.uploadFileTxt {
    margin-top: 60px;
}

.uploadFileTxt p {
    font-size: 16px;
    color: #BFBFBF;
}

.formTabs .nav-tabs {
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.leftFilter {
    position: relative;
}

.leftFilter .filterLabel {
    display: block;
    font-size: 16px;
    color: #505050;
    margin-bottom: 10px;
}

.leftFilter select {
    width: 229px;
    padding: 5px 0;
    border-radius: 3px;
    border: 1px solid #ACACAC;
}

.leftFilter input {
    width: 229px;
    padding: 5px;
    border-radius: 3px;
    border: 1px solid #ACACAC;
}

.searchFilter {
    text-align: right;
}

.searchFilter input {
    width: 364px;
    border: 1px solid #ACACAC;
    border-radius: 3px;
    padding: 10px;
    font-size: 18px;
}

.searchFilter input::placeholder {
    color: #D6D6D6;
}

.assignDirectorBtn {
    text-align: center;
    text-align: -webkit-center;
}

.assignDirectorBtn span {
    display: block;
    background-color: #ECECEC;
    border-radius: 50%;
    padding: 20px;
    height: 70px;
    width: 70px;
    margin: 0 auto 10px;

}

.assignDirectorBtn span svg {
    font-size: 31px;
    fill: #1A73E8;
}

.assignDirectorBtn button {
    background-color: transparent;
    border: none;
    padding: 0;
    color: #1A73E8;
    font-size: 16px;
}

.inviteModal .modal-dialog.modal-sm {
    --bs-modal-width: 700px !important;
}

.inviteModal .modalHeading {
    text-align: left;
}

.inviteModal .modalHeading h2 {
    font-size: 38px;
    color: #505050;
    margin: 0;
}

.inviteModal .modalHeading p {
    font-size: 22px;
    color: #ACACAC;
}

.inviteModal .modalHeading p b {
    color: #505050;
}

.inviteModal .crossBtn {
    text-align: right;
}

.inviteModal .crossBtn button {
    background-color: #F1F1F1;
    border: 1px solid #ACACAC;
}

.inviteModal .crossBtn button svg {
    fill: #FDA61C;
}

.inviteModal .modalForm {}

.inviteModal .modalForm input {
    width: 100%;
    padding: 10px;
    border: 1px solid #707070;
    border-radius: 8px;
}

.inviteModal .modalForm textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #707070;
    border-radius: 8px;
    min-height: 204px;
}

.copyBtn {
    background-color: transparent;
    border: none;
    padding: 0;
    font-size: 13px;
    color: #ACACAC;
}

.copyBtn span {
    background-color: #1A73E8;
    border-radius: 50%;
    padding: 15px;
    display: inline-flex;
    height: 22px;
    width: 22px;
    align-items: center;
    justify-content: center;
}

.copyBtn span svg {
    fill: #fff;
    font-size: 13px;
}

.adminInviteModal .modal-dialog.modal-sm {
    --bs-modal-width: 700px !important;
}

.adminInviteModal .modalHeading {
    text-align: left;
}

.adminInviteModal .modalHeading h2 {
    font-size: 38px;
    color: #505050;
    margin: 0;
}

.adminInviteModal .modalHeading p {
    font-size: 22px;
    color: #ACACAC;
}

.adminInviteModal .modalHeading p b {
    color: #505050;
}

.adminInviteModal .crossBtn {
    text-align: right;
}

.adminInviteModal .crossBtn button {
    background-color: #F1F1F1;
    border: 1px solid #ACACAC;
}

.adminInviteModal .crossBtn button svg {
    fill: #FDA61C;
}

.adminInviteModal{
    z-index: 1100;
}

.adminInviteModal .modalForm textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #707070;
    border-radius: 8px;
    min-height: 204px;
}

.adminInviteModal .copyBtn {
    background-color: transparent;
    border: none;
    padding: 0;
    font-size: 16px;
    color: #ACACAC;
}

.adminInviteModal .copyBtn span {
    background-color: #1A73E8;
    border-radius: 50%;
    padding: 15px;
    display: inline-flex;
    height: 22px;
    width: 22px;
    align-items: center;
    justify-content: center;
}

.adminInviteModal .copyBtn span svg {
    fill: #fff;
    font-size: 13px;
}

.checkMarkIcon svg {
    fill: #00BE0D;
}

.passwordChangeModal .modal-dialog.modal-sm {
    --bs-modal-width: 700px !important;
}

.passwordModalWrp .statusWrp {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.passwordModalWrp .statusWrp .left span {
    font-size: 18px;
    color: #000000;
}

.passwordModalWrp .statusWrp .right span {
    font-size: 18px;
    color: #000000;
    font-weight: 600;
}

.instructionWrp {
    text-align: left;
    margin-top: 60px;
    font-size: 20px;
    color: #363636;
}

.instructionWrp ul li {
    list-style: none;
}

.instructionWrp ul li:not(:last-child) {
    margin-bottom: 20px;
}

.instructionWrp ul li span {
    display: flex;
    font-size: 18px;
    color: #000000;
}

.instructionWrp ul li svg {
    margin-right: 10px;
}

.shareModal .modal-dialog {
    --bs-modal-width: 700px !important;
}

.surveryModalTab button {
    width: 100px;
}

.surveryTabWrp input {
    width: 100%;
    padding: 10px;
    border: 1px solid #707070;
    border-radius: 8px;
}

.surveryTabWrp textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #707070;
    border-radius: 8px;
    min-height: 200px;
    margin-top: 25px;
}

.publishModal .modal-dialog {
    --bs-modal-width: 604px !important;
}

.publishModal p {
    font-size: 18px;
    color: #4D4D4D;
}

.rdrDateRangePickerWrapper {
    position: absolute;
    left: 0;
    top: 80px;
    z-index: 100;
}

.calendarBtn {
    width: 229px;
    padding: 5px 10px;
    border-radius: 3px;
    border: 1px solid #ACACAC;
    text-align: left;
    background: transparent;
}

.twoTableWrp {
    margin-top: 60px;
}

.twoTableWrp .sideHeading {
    background-color: #F2F2F2;
    padding: 142px 30px 30px 30px;
    height: 100%;
}

.twoTableWrp .sideHeading h3 {
    font-size: 22px;
    color: #6D6D6D;
    margin: 0;
}

.twoWayTableWrp .upperTxt {
    text-align: center;
    background-color: #F2F2F2;
    padding: 24px 0;

}

.twoWayTableWrp .upperTxt h3 {
    font-size: 22px;
    color: #6D6D6D;
    margin: 0;
}

.twoWayTableWrp .tableContent thead tr th {
    font-size: 16px;
    color: #707070;
    vertical-align: middle;
    text-align: center;
    border: 1px solid #E2E2E2;
}

.twoWayTableWrp .tableContent tbody tr td {
    font-size: 16px;
    color: #707070 !important;
    vertical-align: middle;
    border: 1px solid #E2E2E2;
}

/* .formTabs .nav-link{
    padding: 0 !important;
} */

.avatar {
    background-color: #007bff;
    color: white;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
  }
  
  .mt-3 {
    margin-top: 1rem;
  }
  
  .mt-4 {
    margin-top: 1.5rem;
  }
  
  .ms-2 {
    margin-left: 0.5rem;
  }
  
  .d-flex {
    display: flex;
  }
  
  .align-items-center {
    align-items: center;
  }

  .modebar-group:nth-child(2) {
    display: none !important;
  }